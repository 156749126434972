import { Parallax } from "react-parallax";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './bootstrap.css';
import './App.css';


function App() {
  const image1 = "https://i.ibb.co/tpr2N7h/slide-5.jpg";
  const insideStyles = {
    background: "transparent",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "white",
    fontSize: 48
  };

  return (
    <div className="App">
      <Parallax bgImage={image1} strength={500}>
        <div style={{ height: 500 }}>
          <div style={insideStyles}>Optimus Consulting</div>
        </div>
      </Parallax>
      {/* about section */}
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <img
                src="https://i.ibb.co/G0FFJJ0/about-img.png"
                className="img-responsive wow fadeInUp animated"
                alt="About"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              />{" "}
            </div>{" "}
            <div className="col-md-6 col-sm-12">
              <div className="about-thumb">
                <div className="section-title">
                  <h1
                    className="wow fadeIn animated"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeIn",
                    }}
                  >
                    About Optimus{" "}
                  </h1>{" "}
                  <h3
                    className="wow fadeInUp animated"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.4s",
                      animationName: "fadeInUp",
                    }}
                  >
                    Personalized consulting for industrial enterprises{" "}
                  </h3>{" "}
                </div>{" "}
                <div
                  className="wow fadeInUp animated"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInUp",
                  }}
                >
                  <p>
                    Optimus FZE is a{" "}
                    <strong>
                      {" "}
                      full - service business development group and general
                      management consulting firm{" "}
                    </strong>{" "}
                    focused on small and medium - size privately - held
                    companies all over the world.{" "}
                  </p>{" "}
                  <p>
                    Optimus has helped more than 200 small and medium - size
                    businesses maintain positive cash flow, control costs and
                    accelerate profitable growth, allowing entrepreneurs and
                    managers to make the most of their work and their lives.{" "}
                  </p>{" "}
                  <p>
                    The company is working under{" "}
                    <strong>
                      {" "}
                      Free Trade Zone Consultancy license№ 4927 by 5{" "}
                      <sup> th </sup> April 2018.{" "}
                    </strong>{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </section>{" "}
      {/* feature section */}
      <section id="feature">
        <div className="container">
          <div className="row">
            <svg
              preserveAspectRatio="none"
              viewBox="0 0 100 102"
              height={100}
              width="100%"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              className="svgcolor-light"
            >
              <path d="M0 0 L50 100 L100 0 Z" />
            </svg>{" "}
            <div className="col-md-4 col-sm-6">
              <div
                className="media wow fadeInUp animated"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="media-object media-left">
                  <i className="icon icon-laptop" />
                </div>{" "}
                <div className="media-body">
                  <h2 className="media-heading"> Program / Planning </h2>{" "}
                  <p>
                    Development of concept, conceptual schedule, and budget,
                    as well as budget development, project analysis and
                    reporting, risk assessment and management plan{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="col-md-4 col-sm-6">
              <div
                className="media wow fadeInUp animated"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="media-object media-left">
                  <i className="icon icon-clipboard" />
                </div>{" "}
                <div className="media-body">
                  <h2 className="media-heading"> Design </h2>{" "}
                  <p>
                    A / E / Consultant selection, design process management,
                    master schedule development, detailed estimating, value
                    engineering, progress analysis and reporting, risk
                    management plan update{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="col-md-4 col-sm-8">
              <div
                className="media wow fadeInUp animated"
                style={{
                  visibility: "visible",
                  animationDelay: "1.2s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="media-object media-left">
                  <i className="icon icon-chat" />
                </div>{" "}
                <div className="media-body">
                  <h2 className="media-heading"> Procurement </h2>{" "}
                  <p>
                    Contractor identification, scope of work analysis, bidding
                    strategies, prequalification, and processing; construction
                    contracts, negotiations, permit expediting.{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="clearfix text-center col-md-12 col-sm-12">
              <a
                href="tel:+971 568549201"
                className="btn btn-default smoothScroll"
              >
                Contact us{" "}
              </a>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </section>{" "}
      <section id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-offset-2 col-md-8 col-sm-12">
              <div className="section-title">
                <h1
                  className="wow fadeInUp animated"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp",
                  }}
                >
                  write us{" "}
                </h1>{" "}
                <p
                  className="wow fadeInUp animated"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInUp",
                  }}
                >
                  Find out how Optimus can help your complex project{" "}
                </p>{" "}
                <h3
                  className="wow fadeInUp animated"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInUp",
                  }}
                >
                  +971 5685 49201 | pek @consultingoptimus.com{" "}
                </h3>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </section>{" "}
      {/* footer section */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <h2>Optimus</h2>
              <div className="wow fadeInUp" data-wow-delay="0.3s">
                <p className="copyright-text">
                  © 2016 - 2021 Optimus
                </p>
              </div>
            </div>
            <div className="col-md-1 col-sm-1" />
            <div className="col-md-4 col-sm-5">
              <h2>Office</h2>
              <p className="wow fadeInUp" data-wow-delay="0.6s">
                Business centre <br />
                AI Smookh building <br />
                UAQ Free Trading Zone <br />
                Umm AI Quwain, U.A.E. <br />
                PO Box 7073{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div >
  );
}

export default App;
